@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: "Bosch";
	src: local("Bosch"), url("./fonts/Bosch.otf") format("truetype");
	font-weight: bold;
}

@layer base {
	h1 {
		@apply text-4xl md:text-8xl;
	}
	h2 {
		@apply text-xl md:text-2xl uppercase;
	}
	h3 {
		@apply text-lg md:text-xl;
	}
	h4 {
		@apply text-lg;
	}
	p {
		@apply text-base;
	}
	h5 {
		@apply text-lg;
	}
}

html,
body {
	margin: 0;
	padding: 0;
}

body {
	margin: 0;
	font-family: "Inter";
	/* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.bg-animated {
	animation-name: color;
	animation-duration: 25s;
	animation-iteration-count: infinite;
}

@keyframes color {
	0% {
		background-color: rgb(251 146 60);
	}
	50% {
		background-color: rgb(249 115 22);
	}
	100% {
		background-color: rgb(251 146 60);
	}
}
